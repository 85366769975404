import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import moment from "moment"
import vuetify from "./plugins/vuetify"
import DateFilter from "./filters/date" // Import date

import VueExcelXlsx from "vue-excel-xlsx"

import { auth, usersRef } from "./fb.js"

import { TiptapVuetifyPlugin } from "tiptap-vuetify"

import { firestorePlugin } from "vuefire"
//import { VueFire } from 'vuefire'
import "tiptap-vuetify/dist/main.css"
import "vuetify/dist/vuetify.min.css"
import JsonExcel from "vue-json-excel"

Vue.component("downloadExcel", JsonExcel)

Vue.filter("date", DateFilter) // register filter globally

Vue.filter("DisplayDateFilter", function (tmpdate) {
  if (tmpdate) {
    if (tmpdate.length <= 10) {
      var monarray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      var date = tmpdate.substring(8, 10)
      var year = tmpdate.substring(0, 4)
      var month = parseInt(tmpdate.substring(5, 7))
      if (month <= 12) {
        var newmonth = monarray[month - 1]
        var newdate = date + "-" + newmonth + "-" + year
        return newdate
      } else {
        return tmpdate
      }
    } else {
      return tmpdate
    }
  } else {
    return tmpdate
  }
})

Vue.config.productionTip = false
Vue.prototype.moment = moment
Vue.use(firestorePlugin)

Vue.use(VueExcelXlsx)
//Vue.use(VueFire);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "md",
})

let app
auth.onAuthStateChanged(async (user) => {
  if (!app) {
    if (user) {
      var passvalue = { role: {} }

      user.getIdTokenResult().then((idTokenResult) => {
        user.superadmin = idTokenResult.claims.superadmin
        user.admin = idTokenResult.claims.admin
        passvalue.role = idTokenResult.claims
      })

      //console.log("user logged in", user.email)
      await usersRef
        .where("email", "==", user.email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            passvalue.menufrom = "/login"
            // passvalue.role=doc.data().role;
            passvalue.domain = doc.data().domain
            passvalue.EmpID = doc.data().empid
            passvalue.email = doc.data().email
            passvalue.name = doc.data().name
            passvalue.mgrname = doc.data().mgrName
            passvalue.mgremail = doc.data().mgremail
            passvalue.uid = doc.id
            passvalue.userconfig = doc.data().userconfig
            passvalue.itemsPerPage = 10
            if (passvalue.userconfig) {
              for (var i = 0; i < passvalue.userconfig.length; ++i) {
                if (passvalue.userconfig[i].description == "Items Per Page")
                  passvalue.itemsPerPage = parseInt(passvalue.userconfig[i].value)
              }
            }
          })

          console.log("passvalue from onAuthState change is", passvalue)
          store.dispatch("setuserprofile", passvalue)
        })
    } else {
      console.log("no user logged in")
      store.dispatch("setlogoutfalse")
    }

    app = new Vue({
      router,
      store,
      vuetify,
      moment,

      render: (h) => h(App),
    }).$mount("#app")
  }
})
