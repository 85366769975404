import Vue from "vue"
import Vuetify from "vuetify/lib"

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: "md",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#1976D2",
        secondary: "#247BA0",
        tertiary: "#ECEFF1",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FF8F00",
        labels: "#9E9E9E",
        background: "#FFFFFF",
        document: "#B2DBBF",
        meloncolor: "#E9AFA3",
        savecolor: "#9b9b7a",
        cancelcolor: "#d9ae94",
        editcolor: "#d08c60",
        iconcolor: "#f5f3f4",
      },
    },
  },
})
//primary,secondary,tertiary,accent,info,meloncolor,savecolor,editcolor,iconcolor
//"#ECEFF1","#82B1FF","#2196F3","#9E9E9E","#B2DBBF",:"#E9AFA3",:"#f5f3f4","#FF5252",
