import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userProfile: {},
    loggedin: false,
    currentmenu: "",
  },
  mutations: {
    SET_USER_PROFILE(state, profile) {
      state.userProfile = profile
      state.loggedin = true
      // this.$emit("loggedin",profile);
    },
    SET_CURRENT_MENU(state, menu) {
      state.userProfile.menufrom = menu
    },
    SET_LOG_OUT(state) {
      state.loggedin = false
    },
  },
  actions: {
    async setuserprofile(context, profile) {
      console.log("inside action setuserprofile", profile)
      await context.commit("SET_USER_PROFILE", profile)
    },
    async setcurrentmenu(context, menu) {
      console.log("inside action setcurrentmenu", menu)
      await context.commit("SET_CURRENT_MENU", menu)
    },
    setlogoutfalse(context) {
      context.commit("SET_LOG_OUT")
    },
  },
  modules: {},
})
