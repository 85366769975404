import Vue from "vue"
import VueRouter from "vue-router"
import { auth } from "@/fb"
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },

  {
    path: "/ITService",
    name: "ITService",
    component: () => import(/* webpackChunkName: "itservice" */ "../views/ITRequest/servicerequest.vue"),
    meta: { requiresAuth: true },
    props: true,
  },

  {
    path: "/profiles",
    name: "profiles",
    component: () => import(/* webpackChunkName: "profiles" */ "../views/recruitment/profiles.vue"),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/jobs",
    name: "jobs",
    component: () => import(/* webpackChunkName: "jobs" */ "../views/recruitment/jobs.vue"),
    meta: { requiresAuth: true },
    props: true,
  },

  {
    path: "/HRdetails",
    name: "HRdetails",
    component: () => import(/* webpackChunkName: "hrdetails" */ "../views/HR/HRdetails.vue"),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/Domainmgt",
    name: "domainmgt",
    component: () => import(/* webpackChunkName: "domainmgt" */ "../views/Domain/domainmgt.vue"),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/uploads",
    name: "uploads",
    component: () => import(/* webpackChunkName: "uploads" */ "../components/upload/uploads.vue"),
    meta: { requiresAuth: true },
    props: true,
  },

  {
    path: "/clientprofile/:id",
    name: "clientprofile",
    component: () => import(/* webpackChunkName: "clientprofile" */ "../views/recruitment/clientprofile.vue"),
    props: true,
  },
 

  {
    path: "/reviewpdp/:id",
    name: "reviewpdp",
    component: () => import(/* webpackChunkName: "reviewpdp" */ "../views/PDP/reviewpdp.vue"),
    props: true,
  },

  {
    path: "/Configmgt",
    name: "configmgt",
    component: () => import(/* webpackChunkName: "configmgt" */ "../views/Domain/configmgt.vue"),
    meta: { requiresAuth: true },
    props: true,
  },

  {
    path: "/Userconfigmgt",
    name: "userconfigmgt",
    component: () => import(/* webpackChunkName: "userconfigmgt" */ "../views/Domain/userconfigmgt.vue"),
    meta: { requiresAuth: true },
    props: true,
  },

  {
    path: "/Leaves",
    name: "Leaves",
    component: () => import(/* webpackChunkName: "leaves" */ "../views/leavemgt/leaves.vue"),
    meta: { requiresAuth: true },
    props: true,
  },

  {
    path: "/employee",
    name: "employee",
    component: () => import(/* webpackChunkName: "employee" */ "../views/Employee.vue"),
    meta: { requiresAuth: true },
    props: true,
  },

  {
    path: "/PDPreview",
    name: "PDPreview",
    component: () => import(/* webpackChunkName: "pdpreview" */ "../views/PDP/PDPreview.vue"),
    meta: { requiresAuth: true },
    props: true,
  },

  {
    path: "/offerletter",
    name: "offerletter",
    component: () => import(/* webpackChunkName: "offerletter" */ "../views/recruitment/OfferLetter.vue"),
    meta: { requiresAuth: true },
    props: true,
  },

  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  // },
  {
    path: "/hradmin",
    name: "hradmin",
    component: () => import(/* webpackChunkName: "hradmin" */ "../views/HRadmin.vue"),
    meta: { requiresAuth: true },
    props: true,
  },

  {
    path: "/sysadmin",
    name: "sysadmin",
    component: () => import(/* webpackChunkName: "sysadmin" */ "../views/SysAdmin.vue"),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/register",
    name: "register",
    component: () => import(/* webpackChunkName: "register" */ "../views/Register.vue"),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/reviewers",
    name: "reviewers",
    component: () => import(/* webpackChunkName: "reviewers" */ "../views/Domain/reviewermgt.vue"),

    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/activity",
    name: "activity",
    component: () => import(/* webpackChunkName: "activity" */ "../views/Activity.vue"),

    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/asset",
    name: "asset",
    component: () => import(/* webpackChunkName: "asset" */ "../views/Asset.vue"),
    meta: { requiresAuth: true },
    props: true,
  },

  {
    path: "/vendor",
    name: "vendor",
    component: () => import(/* webpackChunkName: "vendor" */ "../views/Vendor.vue"),

    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: () => import(/* webpackChunkName: "changepassword" */ "../views/changepassword.vue"),

    meta: { requiresAuth: true },
    props: true,
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, savedPosition) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: "smooth",
      })
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const isAuthenticated = auth.currentUser

  if (requiresAuth && !isAuthenticated) {
    next("/login")
  } else {
    next()
  }
})

export default router
