import firebase from "firebase/app"
import "firebase/firestore"
// import "firebase/storage"
import "firebase/auth"
import "firebase/storage"

// import "firebase/analytics"
// const fbadmin = require("firebase-admin");
// var fs = require('file-system');

/* const firebaseConfig = {
  apiKey: "AIzaSyBuQDAq9UbGPbo-8SYmXRZlz0HUciUxD2Q",
  authDomain: "venzoadmindev.firebaseapp.com",
  databaseURL: "https://venzoadmindev.firebaseio.com",
  projectId: "venzoadmindev",
  storageBucket: "venzoadmindev.appspot.com",
  messagingSenderId: "24563293356",
  appId: "1:24563293356:web:ed6956096bc77cf77c9309",
  measurementId: "G-2YY7R8GYJ3",
  ignoreUndefinedProperties: true,
  sspurl: "venzoadmindev.firebaseapp.com/",
  adduserurl: "https://addusersgen-rv6cprgx3a-uc.a.run.app",
  sendmailurl: "https://sendmailsgen-rv6cprgx3a-uc.a.run.app",
  updateUserRolesurl: "https://updateuserrolessgen-rv6cprgx3a-uc.a.run.app",
  getUserRolesurl: "https://getuserdetailssgen-rv6cprgx3a-uc.a.run.app",
  updateemailurl: "https://updateemailsgen-rv6cprgx3a-uc.a.run.app",
  deleteemailurl: "https://deleteemailsgen-rv6cprgx3a-uc.a.run.app",
  updateClientPDPurl: "https://updateclientpdpsgen-rv6cprgx3a-uc.a.run.app",

//   adduserurl: "https://us-central1-venzoadmindev.cloudfunctions.net/addUser",
//   sendmailurl: "https://us-central1-venzoadmindev.cloudfunctions.net/sendMail",
//   updateUserRolesurl: "https://us-central1-venzoadmindev.cloudfunctions.net/updateUserRoles",
//   getUserRolesurl: "https://us-central1-venzoadmindev.cloudfunctions.net/getUserDetails",
//   updateemailurl: "https://us-central1-venzoadmindev.cloudfunctions.net/updateEmail",
//   deleteemailurl: "https://us-central1-venzoadmindev.cloudfunctions.net/deleteEmail",
//   updateClientPDPurl: "https://us-central1-venzoadmindev.cloudfunctions.net/updateClientPDP",
 }*/

 var firebaseConfig = {
  apiKey: "AIzaSyAHyUGYeoJqMQLiuogyTeO06ov3Sdej87w",
  authDomain: "selfserviceportal-aa3f2.firebaseapp.com",
  databaseURL: "https://selfserviceportal-aa3f2.firebaseio.com",
  projectId: "selfserviceportal-aa3f2",
  storageBucket: "selfserviceportal-aa3f2.appspot.com",
  messagingSenderId: "610333832904",
  appId: "1:610333832904:web:889b7a71da1d96cf7e97fd",
  measurementId: "G-6NSE66VPRR",
  ignoreUndefinedProperties: true,
  //sspurl: "https://vssp.venzoconsulting.com/",
  sspurl: "https://vssp.venzotechnologies.com/",
  adduserurl: "https://addusersgen-ramjyh2hea-uc.a.run.app",
  sendmailurl: "https://sendmailsgen-ramjyh2hea-uc.a.run.app",
             //"https://sendmailsgen-ramjyh2hea-uc.a.run.app"
  updateUserRolesurl: "https://updateuserrolessgen-ramjyh2hea-uc.a.run.app",
  getUserRolesurl: "https://getuserdetailssgen-ramjyh2hea-uc.a.run.app",
  updateemailurl: "https://updateemailsgen-ramjyh2hea-uc.a.run.app",
  deleteemailurl: "https://deleteemailsgen-ramjyh2hea-uc.a.run.app",
  updateClientPDPurl: "https://updateclientpdpsgen-ramjyh2hea-uc.a.run.app",

  // adduserurl: "https://us-central1-selfserviceportal-aa3f2.cloudfunctions.net/addUser",
  // sendmailurl: "https://us-central1-selfserviceportal-aa3f2.cloudfunctions.net/sendMail",
  // updateUserRolesurl: "https://us-central1-selfserviceportal-aa3f2.cloudfunctions.net/updateUserRoles",
  // getUserRolesurl: "https://us-central1-selfserviceportal-aa3f2.cloudfunctions.net/getUserDetails",
  // updateemailurl: "https://us-central1-selfserviceportal-aa3f2.cloudfunctions.net/updateEmail",
  // deleteemailurl: "https://us-central1-selfserviceportal-aa3f2.cloudfunctions.net/deleteEmail",
  // updateClientPDPurl: "https://us-central1-selfserviceportal-aa3f2.cloudfunctions.net/updateClientPDP",
}
const firebaseApp = firebase.initializeApp(firebaseConfig)
// const fbadminapp = fbadmin.initializeApp(firebaseConfig);
const authdomain = firebaseConfig.sspurl
const sendmailurl = firebaseConfig.sendmailurl
const adduserurl = firebaseConfig.adduserurl
const updateUserRolesurl = firebaseConfig.updateUserRolesurl
const getUserRolesurl = firebaseConfig.getUserRolesurl
const updateemailurl = firebaseConfig.updateemailurl
const deleteemailurl = firebaseConfig.deleteemailurl
const updateClientPDPurl = firebaseConfig.updateClientPDPurl

const userconfig = [{ description: "Items Per Page", value: "10", type: "number" }]

console.log(`Launching ${process.env.VUE_APP_FB_PROJECTID}`)

const fstore = firebaseApp.firestore
const db = firebaseApp.firestore()
const auth = firebaseApp.auth()
const Timestamp = firebase.firestore.Timestamp
const activityRef = db.collection("activitylists")
const usersRef = db.collection("userroles")
const assetRef = db.collection("assetlists")
const deleteRef = db.collection("deleteuers")
const emailRef = db.collection("domains")
const empRef = db.collection("employeelists")
const vendorRef = db.collection("vendorlists")
const recruitmentRef = db.collection("profiles")
const clientprofilesRef = db.collection("clientprofiles")
const jobsRef = db.collection("jobs")
const skillsRef = db.collection("skills")
const ITRef = db.collection("itrequests")
const domainsRef = db.collection("domains")
const PDPRef = db.collection("performancereviews")
const clientPDPRef = db.collection("performancereviewsclient")

const configgenRef = db.collection("configgenerals")
const configpdpRef = db.collection("configpdps")
const leavesummariesRef = db.collection("leavesummaries")
const leavedetailsRef = db.collection("leavedetails")
const holidaysRef = db.collection("holidays")
const gmlsRef = db.collection("configgmls")
const configallRef = db.collection("configall")
const reviewersRef = db.collection("reviewers")

export {
  db,
  Timestamp,
  auth,
  firebaseApp,
  fstore,
  usersRef,
  activityRef,
  vendorRef,
  assetRef,
  deleteRef,
  emailRef,
  empRef,
  recruitmentRef,
  skillsRef,
  jobsRef,
  ITRef,
  domainsRef,
  PDPRef,
  configgenRef,
  configpdpRef,
  leavesummariesRef,
  leavedetailsRef,
  holidaysRef,
  gmlsRef,
  configallRef,
  authdomain,
  clientprofilesRef,
  reviewersRef,
  clientPDPRef,
  userconfig,
  adduserurl,
  sendmailurl,
  updateUserRolesurl,
  getUserRolesurl,
  updateemailurl,
  deleteemailurl,
  updateClientPDPurl,
}
