<template>
  <v-app id="keep">
    <v-app-bar app clipped-left class="appbar hidden-print-only">
      <v-app-bar-nav-icon v-if="logged_in" @click="drawer = !drawer">
        <v-avatar color="gray" size="48">
          <v-icon class="grey--text">{{ navicon }}</v-icon>
        </v-avatar>
      </v-app-bar-nav-icon>
      <div>
        <v-img
          alt="Venzo Logo"
          class="shrink mr-2"
          contain
          src="@/assets/venzologo.png"
          transition="scale-transition"
          width="50px"
          height="60px"
        />
      </div>
      <v-spacer></v-spacer>

      <div class="mr-2 font-italic" v-if="logged_in">
        Username<span class="font-weight-bold"> {{ name }} </span>
        <div>
          Domain<span class="font-weight-bold"> {{ headingdomain }} </span>
        </div>
      </div>

      <span v-if="logged_in">
        <v-menu bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon x-large v-on="on">
              <v-avatar color="brown" size="48">
                <span class="white--text headline"> {{ avatarname }} </span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-btn depressed rounded text @click="usersettings"> User Settings </v-btn>
                <v-divider></v-divider>
                <v-btn depressed rounded text @click="changePasswordclick"> Change Password </v-btn>
                <v-divider></v-divider>
                <v-btn depressed rounded text @click="signoutclick"> Signout </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </span>
    </v-app-bar>

    <v-navigation-drawer
      class="hidden-print-only"
      v-if="logged_in"
      v-model="drawer"
      app
      clipped
      color="#16283F"
      :width="225"
    >
      <v-list dense class="navbarcolor">
        <template v-for="(item, i) in items">
          <v-row v-if="item.heading" :key="i" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn small text>edit</v-btn>
            </v-col>
          </v-row>
          <v-divider v-else-if="item.divider" :key="i" dark class="my-4"></v-divider>
          <v-list-item v-else :key="i" @click="clickitem(item)">
            <v-list-item-action color="#5DAAED">
              <v-icon class="grey--text">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="white--text">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view
        @loggedin="userlogin($event)"
        @disablenav="drawer = false"
        @signOut="logged_in = false"
      ></router-view>
    </v-main>
    <Confirm ref="confirm" />
  </v-app>
</template>

<script>
import { auth } from "@/fb.js"
import Confirm from "@/components/common/Confirm.vue"
export default {
  components: {
    Confirm,
  },
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,

    items: [],
    navicon: "menu",
    role: "",
    domain: "",
    headingdomain: "",
    name: "",
    EmpID: "",
    email: "",
    avatarname: "",
    mgrname: "",
    mgremail: "",
    roles: {
      role: "",
      menufrom: "",
      domain: "",
    },
    logged_in: false,
    avt_logged_in: false,
  }),
  async created() {
    console.log("inside app.vue created")
    this.logged_in = await this.$store.state.loggedin

    if (this.logged_in) this.userlogin(await this.$store.state.userProfile)
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open
  },

  methods: {
    signoutclick() {
      try {
        auth.signOut()
        console.log("signout done")
        this.$store.dispatch("setlogoutfalse");
       
       // this.logged_in = false
        //  this.$router.replace({ name: "login" });
        this.$router.push({ name: "login" })
      } catch (err) {
        console.log("Error in signout:", err)
      }
    },
    usersettings() {
      this.$router.push({ name: "userconfigmgt" })
    },
    changePasswordclick() {
      this.$router.push({ name: "changepassword", params: { roles: this.roles } })
    },
    clickitem(item) {
      console.log(" inside clickitem ", item.action, this.$route.name)
      if (item.action == "signout") {
        try {
          auth.signOut()
          console.log("signout done")
          this.$store.dispatch("setlogoutfalse");
          //this.logged_in = false
          //  this.$router.replace({ name: "login" });
          this.$router.push({ name: "login" })
        } catch (err) {
          console.log("Error in signout:", err)
        }
      } else {
        this.$router.push({ name: item.link })
      }
    },
    userlogin(values) {
      console.log("inside userlogin function", values)
      this.logged_in = true
      this.items = []
      this.role = values.role
      this.domain = values.domain
      this.name = values.name
      this.avatarname = this.name[0]
      this.email = values.email
      this.EmpID = values.EmpID
      this.headingdomain = this.domain.charAt(0).toUpperCase() + this.domain.slice(1)
      this.mgrname = values.mgrname
      this.mgremail = values.mgremail

      if (this.role.superadmin && this.domain == "venzo") {
        this.items.push({ text: "Users", action: "User Mgt", link: "register", icon: "mdi-human", menu: "domainmenu" })
        this.items.push({
          text: "Reviewers",
          action: "Reviewer Mgt",
          link: "reviewers",
          icon: "mdi-human",
          menu: "domainmenu",
        })
        this.items.push({
          text: "Domain",
          action: "Domain Mgt",
          link: "domainmgt",
          icon: "mdi-human",
          menu: "domainmenu",
        })
        this.items.push({
          text: "Config",
          action: "Config Mgt",
          link: "configmgt",
          icon: "settings",
          menu: "domainmenu",
        })
        this.items.push({ text: "Upload", action: "Upload Mgt", link: "uploads", icon: "settings", menu: "domainmenu" })
      }
      if (this.role.superadmin && this.domain != "venzo") {
        this.items.push({ text: "Users", action: "User Mgt", link: "register", icon: "mdi-human", menu: "domainmenu" })
        this.items.push({
          text: "Config",
          action: "Config Mgt",
          link: "configmgt",
          icon: "settings",
          menu: "domainmenu",
        })
        this.items.push({
          text: "Reviewers",
          action: "Reviewer Mgt",
          link: "reviewers",
          icon: "mdi-human",
          menu: "domainmenu",
        })
      }
      if (this.role.hradmin || this.role.readuser) {
        console.log("inside hradmin setmenus")
        this.items.push({
          text: "HR Dashboard",
          action: "HRAdmin Main",
          link: "hradmin",
          icon: "mdi-human",
          menu: "hrmenu",
        })
        this.items.push({
          text: "HR Details",
          action: "HRdetails",
          link: "HRdetails",
          icon: "mdi-format-list-bulleted-type",
          menu: "hrmenu",
        })
        // this.items.push(  { text: 'Employee Details' ,action:"Employee Details", link:"employee",icon:"work",menu:"empmenu"},);
      }

      if (this.role.recruitment || this.role.readuser || this.role.hradmin) {
        console.log("inside hradmin setmenus")
        this.items.push({
          text: "Recruitment Pipeline",
          action: "Recruitment Profile",
          link: "profiles",
          icon: "mdi-human",
          menu: "recruitmentmenu",
        })
        this.items.push({
          text: "Job Postings",
          action: "Recruitment Role",
          link: "jobs",
          icon: "mdi-human",
          menu: "recruitmentmenu",
        })
      }

      if (this.role.employee) {
        console.log("inside employee setmenus")
        this.items.push({
          text: "Employee Details",
          action: "Employee Details",
          link: "employee",
          icon: "work",
          menu: "empmenu",
        })
        this.items.push({
          text: "Performance Review",
          action: "Employee Details",
          link: "PDPreview",
          icon: "rate_review",
          menu: "empmenu",
        })
        this.items.push({
          text: "Leave",
          action: "Employee Details",
          link: "Leaves",
          icon: "work_off",
          menu: "empmenu",
        })
      }
      if (this.role.sysadmin || this.role.readuser) {
        console.log("inside sysadmin setmenus")
        this.items.push({
          text: "Admin Dashboard",
          action: "sysAdmin Main",
          link: "sysadmin",
          icon: "mdi-view-dashboard",
          menu: "sysadminmenu",
        })
        this.items.push({
          text: "Activity List",
          action: "Activity Lists",
          link: "activity",
          icon: "mdi-sitemap",
          menu: "sysadminmenu",
        })
        this.items.push({
          text: "Asset List",
          action: "Asset Lists",
          link: "asset",
          icon: "mdi-monitor-multiple",
          menu: "sysadminmenu",
        })
        this.items.push({
          text: "Vendor List",
          action: "Vendor Lists",
          link: "vendor",
          icon: "mdi-shopping",
          menu: "sysadminmenu",
        })
      }
      this.items.push({ divider: true })
      this.items.push({ text: "Service Request", action: "servicerequest", link: "ITService", icon: "support" })
      this.items.push({ divider: true })

      /* if (this.role.superadmin) {
            this.$router.push({ name: "register", params: { roles: this.passvalue } })
          } else if (this.role.hradmin) {
            this.$router.push({ name: "hradmin", params: { roles: this.passvalue } })
          } else if (this.role.sysadmin) {
            this.$router.push({ name: "sysadmin", params: { roles: this.passvalue } })
          } else if (this.role.readuser) {
            this.$router.push({ name: "hradmin", params: { roles: this.passvalue } })
          } else if (this.role.employee) {
            this.$router.push({ name: "employee", params: { roles: this.passvalue } })
          } else {
           
            alert("Role is not defined please check with admin")
          }*/
      // this.items.push(  {  text: 'Change Password',action:"changepassword" ,icon:"mdi-key-change"},);
      //  this.items.push(  { text: 'Signout' ,action:"signout",icon:"logout"},);
    }, // end of userlogin
  }, //end of methods
}
</script>

<style>
#keep .v-navigation-drawer__border {
  display: none;
}
.appbar {
  background-color: lightblue;
}
.navbarcolor {
  background-color: green;
}
</style>
